.pageWrapper {
  min-height: 100vh;
  //background-image:url('bgImage.jpg');
  background-color: #caf0f8;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;

}

.contentArea {
  flex-grow: 1;
  max-width: 1300px;
  margin: 0 auto;
  background-color: #caf0f8;
}

.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 30px;
  background-color: #0b0c0d;
  color: beige;
}

.list-group-item {
  background-color: $white !important;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;

  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}

.button-group .btn {
  margin: 3px;
}

table {

  th,
  td {
    background-color: $white !important;
  }

  th {
    font-weight: 500;
  }
}

.dropdown-menu {
  background-color: $white !important;
}