/*******************/
// Theme Fonts
/********************/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
$font-family-sans-serif: "Montserrat", sans-serif;
/*******************/
// Theme Colors
/********************/
$primary: black;
$info: #26c6da;
$danger: #f62d51;
$success: #39c449;
$warning: #ffbc34;
$dark: #070808;
$light: #0077b6;
$secondary: #35363b;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "light-primary": #d6e4f3,
  "light-success": #d5f3f2,
  "light-info": #d3edfa,
  "light-warning": #f8ecdc,
  "light-danger": #f8dddd,
);
// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f2f7f8 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #757e85 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1e2a35 !default;
$black: #000 !default;

/*******************/
// Theme Text Contrast
/********************/
$min-contrast-ratio: 2;
$font-weight-bold: 500 !default;
/*******************/
// Theme body bg & text color
/********************/
$body-bg: $gray-200 !default;
$body-color: $gray-900 !default;
$gradient: linear-gradient(-90deg, rgba($white, 0.5), rgba($white, 0)) !default;

/*******************/
// Theme common variable
/********************/
$border-radius: 0.3rem !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.05);
$headings-font-weight: 400 !default;
$h5-font-size: 1.05rem !default;
$h6-font-size: 0.875rem !default;
$card-border-width: 0 !default;
$card-box-shadow: $box-shadow !default;
$grid-gutter-width: 1.85rem !default;

$input-bg: $white !default;
/*******************/
// Theme btn
/********************/
$btn-focus-width: 0px;

/*******************/
// Theme sidebar width
/********************/
$sidebarWidth: 260px;
$sidebarColor: $dark;
